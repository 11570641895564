﻿table.table > :not(:first-child) {
    border-top: 0px solid;
}

table.table {
    line-height: 1.4;
}

.table-titre {
    font-weight: 700;
    background-color: var(--simple-table-border);
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: var(--simple-table-border);
    border-radius: 5px 5px 0px 0px;
    max-height: 27px;
    width: 100%;
    padding: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-header-content {
    font-weight: 700;
    border-style: none;
    width: 100%;
    padding-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-footer-content {
    font-weight: 700;
    border-style: none;
    width: 100%;
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table::-webkit-scrollbar-track {
    border: 1px solid var(--simple-table-header-background);
}

.table.has-footer {
    border-bottom: none !important;
    margin-bottom: 0;
}

.table-footer {
    font-weight: 400;
    padding: 5px;
    background-color: var(--simple-table-header-background);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: var(--simple-table-border);
    border-radius: 0px 0px 5px 5px;
}

.table-container.fit {
    max-width: fit-content;
}

.table-container {
    padding: 0 1px 0 0;

    table tbody td {
        padding-right: 0.3rem;
    }

    table {
        border-left: 1px solid var(--simple-table-border);
        border-right: 1px solid var(--simple-table-border);
        border-bottom: 1px solid var(--simple-table-border);
        border-top: none;
        border-collapse: separate;
        border-spacing: 0px;
        margin-bottom: 0px;
    }

    table.no-title {
        border-top: 1px solid var(--simple-table-border);
    }

    .table-filter-div {
        opacity: 1;
        visibility: visible;
        max-height: 50px;
        min-height: 21px;
        transition: all 0.25s, visibility 0.25s, max-height 0.25s;
    }

    .table-filter-div-hidden {
        visibility: hidden;
        max-height: 0;
        height: 0;
        min-height: 0;
        opacity: 0;
        transition: all 0.25s, visibility 0.25s, max-height 0.25s;
    }

    .width-auto {
        width: auto;
    }

    .width-fit-content {
        width: fit-content;
    }

    .width-min-content {
        width: min-content;
    }

    .width-no-set {
    }

    table, thead, th, tr, td {
        border-color: var(--simple-table-border);
    }

    .selected-row {
        font-weight: 600;
        background-color: var(--simple-background);
    }

    .column-check {
        text-align: center;
        cursor: pointer;
    }

    .table-fixed {
        border-top: none !important;
    }

    .table-fixed {
        thead {
            position: sticky;
            top: 0px;
            border-top: none !important;
            border-bottom: none !important;
            box-shadow: inset 0 -1px 0 var(--simple-table-border), inset 0 1px 0 var(--simple-table-border);
            z-index: 1;
        }

        tbody {
            border-top: none !important;
        }

        thead tr {
            border-top: none !important;
            border-bottom: none !important;
        }

        tbody tr:first-of-type {
            border-top: none !important;
        }

        thead td, thead th {
            background-clip: padding-box;
        }
    }



    .table {

        .order-icon {
            vertical-align: middle !important;
            cursor: pointer;
        }

        .table-row {
            height: 20px;
        }

        .header {
            height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        a:link, a:visited {
            color: var(--simple-link-color-alt);
            text-decoration: none;
        }

        a:hover {
            color: var(--simple-link-color);
            text-decoration: underline;
            transition: ease-in-out 0.2s;
        }

        tbody > tr:not(.free-row) > td > div, thead > tr > td > div {
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 25px;
        }

        thead > tr > td > div > span {
            line-height: 22px;
        }

        .cell {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .table.width-not-set {
        td.cell-date:not([style*="width"]) {
            max-width: 85px;
        }

        td.cell-datetime:not([style*="width"]) {
            max-width: 130px;
        }

        td.cell-string:not([style*="width"]) {
            max-width: 250px;
        }
    }

    .table:not(.width-not-set) {

        div.cell-date:not([style*="width"]) {
            width: 85px;
            max-width: 85px;
        }

        div.cell-datetime:not([style*="width"]) {
            width: 130px;
            max-width: 130px;
        }

        div.cell-string:not([style*="width"]) {
            max-width: 250px;
        }

        div.cell-bool {
            text-align: center;
        }
    }

    .table-filter {
        border-style: none;
        width: 100%;
        background-color: white;
        padding: 0.1rem;
        height: 1.3rem;
        border-radius: 0.2rem;
        font-size: 0.68rem;
    }

    .table-filter:focus-visible {
        outline: none;
    }
}

@each $breakpoint, $width in $grid-breakpoints {

    .cell-#{$breakpoint} {
        @extend .d-none;
        @extend .d-#{$breakpoint}-table-cell !optional;
    }
}
